body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hr-lp {
  border-top: 1px solid  #000;
}
.card-header{
  background: #fff!important;
}
.lp-color{
  color: #ad0132!important;
}
.text-custom{
  color: #ad0132;
}
.w-10{width: 10% !important;}
.w-20{width: 20% !important;}

.standartPrice{font-size: 0.8em; font-style: italic;}

