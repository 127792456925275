.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
label {
  width: 100%;
}
.hidden {
  display: none;
}
.errorMessage {
  color: #ad0132;
}
.catHeader {
  font-weight: 600;
  text-transform: uppercase;
  color: #ad0132 !important;
  margin-bottom: 0;
}
.catBody {
  color: #000;
  text-transform: lowercase;
}
.test1 {
  height: 440px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.btn-circle {
  border-radius: 100% !important;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.box-sh {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #ad0132 !important;
}

.lpbgColor {
  background-color: #ad0132 !important;
  color: #fff !important;
}
.lpLogo {
  width: 95%;
}

.link {
  text-decoration: none;
  color: #000000de;
}

.link :hover {
  text-decoration: none !important;
  color: #000000de;
}
.table100 {
  width: 100%;
}
.row100 > th {
  padding: 0 20px;
}
tr > .column1 {
  padding: 10px 20px;
}
.large {
  background-size: cover;
  width: 450px;
  height: 450px;
}
.box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #d3d3d3;
  background: #fff;
  justify-items: center;
  text-align: center;
  margin: 20px 0;
  padding: 20px 0;
  border-radius: 4px;
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
}
.imageP {
  float: left;
}
.textP {
  margin-bottom: 0;
  text-align: left;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  cursor: pointer;
}
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #343a40!important;
  color: white; }
  .Collapsible__trigger:after {
    /* font-family: 'FontAwesome'; */
    content: '+';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .Collapsible__trigger.is-open:after {
    content: '-';
    transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: red; }
.category__trigger--open{
  background-color: red;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black; }
  
  .category__trigger:after {
    /* font-family: 'FontAwesome'; */
    content: '+';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .category__trigger.is-open:after {
    content: '-';
    transform: rotateZ(180deg); }
  .category__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }


    .subcategory__trigger:after {
      /* font-family: 'FontAwesome'; */
      content: '+';
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
      transition: transform 300ms; }
    .subcategory__trigger.is-open:after {
      content: '-';
      transform: rotateZ(180deg); }
    .subcategory__trigger.is-disabled {
      opacity: 0.5;
      background-color: grey; }
    

  .category__trigger.is-open{
    display: block;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    background: #AAAAAA!important;
    color: black;
  }
  .category__trigger.is-closed{
    display: block;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    background: #AAAAAA!important;
    color: black;
  }
  .subcategory__trigger.is-open{
    display: block;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    background: #DDDDDD!important;
    color: black;
  }
  .subcategory__trigger.is-closed{
    display: block;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    background: #DDDDDD!important;
    color: black;
  }


  .subcategory__contentInner > .row {
    border-bottom: 1px solid #DDDDDD!important;
  }

  select {
    /* styling */
    background-color: white;
    border: thin solid #6666;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
   overflow: none;
    /* reset */
    margin: 1rem;      
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  }

  .select-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  #board-select {
    margin-bottom: 0;
  }

  .checkbox {
  display: inline-block;
  padding: 10px 20px;
  transform: translateZ(0);
}
.checkbox label {
  cursor: pointer;
  padding-left: 0;
}
.checkbox input[type=checkbox] {
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
}
.checkbox .checkbox-material {
  vertical-align: middle;
  position: relative;
  top: 1px;
  margin-right: 10px;
}
.checkbox .checkbox-material:before {
  position: absolute;
  left: 8px;
  top: 2px;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  height: 4px;
  width: 4px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
}
.checkbox .checkbox-material .check {
  position: relative;
  display: inline-block;
  width: 19px;
  height: 20px;
  border: 1px solid;
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
}
.checkbox .checkbox-material .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -4px;
  margin-left: 6px;
  width: 0;
  height: 0;
  color: #303030;
  opacity: 0.6;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  -webkit-animation: checkbox-off 0.3s forwards ease-out;
          animation: checkbox-off 0.3s forwards ease-out;
}
.checkbox input[type=checkbox]:focus + .checkbox-material .check:after {
  opacity: 0.2;
}
.checkbox input[type=checkbox]:checked + .checkbox-material .check:before {
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  -webkit-animation: checkbox-on 0.3s forwards ease-out;
          animation: checkbox-on 0.3s forwards ease-out;
}
.checkbox input[type=checkbox]:not(:checked) + .checkbox-material:before {
  -webkit-animation: rippleOff 700ms forwards ease-out;
          animation: rippleOff 700ms forwards ease-out;
}
.checkbox input[type=checkbox]:checked + .checkbox-material:before {
  -webkit-animation: rippleOn 700ms forwards ease-out;
          animation: rippleOn 700ms forwards ease-out;
}
.checkbox input[type=checkbox]:not(:checked) + .checkbox-material .check:after {
  -webkit-animation: rippleOff 700ms forwards ease-out;
          animation: rippleOff 700ms forwards ease-out;
}
.checkbox input[type=checkbox]:checked + .checkbox-material .check:after {
  -webkit-animation: rippleOn 700ms forwards ease-out;
          animation: rippleOn 700ms forwards ease-out;
}
.checkbox input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check:before,
.checkbox input[type=checkbox][disabled] + .circle {
  opacity: 0.5;
}
.checkbox input[type=checkbox][disabled] + .checkbox-material .check:after {
  background-color: rgba(0, 0, 0, 0.84);
  transform: rotate(-45deg);
}

.coloured .checkbox-material .check {
  color: #2FAEF8;
}
.coloured .checkbox-material:before {
  background-color: #2FAEF8;
}
.coloured input[type=checkbox]:checked + .checkbox-material .check {
  color: #2FAEF8;
}
@-webkit-keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@-webkit-keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0px;
    height: 0px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 10px inset;
  }
  100% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 0px inset;
  }
}
@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0px;
    height: 0px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 10px inset;
  }
  100% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 0px inset;
  }
}
@-webkit-keyframes rippleOn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
@-webkit-keyframes rippleOff {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}